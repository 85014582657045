<template>
  <div :class="classCard()">
    <div class="gestao">
      <a @click="$router.push({ name: route })" tabindex="-1">
        <span :class="titleImage"></span>
        <h3 class="gestao-titulo notranslate">
          <i :class="icon"></i> {{ title }}
        </h3>
        <p class="gestao-descricao">
          {{ desc }}
        </p>
        <a
          v-if="mais != 'false'"
          span
          class="btn btn-green"
          @click="$router.push({ name: route })"
          >Saiba mais</a
        >
      </a>
    </div>
  </div>
</template>
<script>
export default {
  props: ["titleImage", "title", "mais", "icon", "desc", "route", "colMd"],
  methods: {
    classCard() {
      var $class = "col-sm-12 slick-slide slick-cloned";
      $class =
        $class +
        (this.colMd
          ? "col-md-6 col-lg-6 col-xl-" + this.colMd
          : " col-md-6 col-lg-6 col-xl-4");
      return $class;
    }
  }
};
</script>
